<template>
  <div v-if="$route.path !== '/auth/login'">
    <Sticky>
      <nav class="navbar navbar-horizontal navbar-expand-lg" style="background: #092305; background-image: url('https://space-tech.sgp1.cdn.digitaloceanspaces.com/vbet22/home/TopBar.png'); background-size: cover;">
        <div class="container-fluid">
          <div
            class="navbar-brand text-white"
            :class="
              $route.path !== '/home' ? 'd-flex justify-content-between' : ''
            "
          >
            <div
              class="d-flex flex-column banking-icons my-auto ml-1 mr-3"
              v-if="$route.path !== '/auth/login'"
            >
              <i
                class="fas fa-chevron-left mx-auto my-1 text-xl"
                @click="$router.go(-1)"
                v-if="$route.path !== '/home'"
              ></i>
            </div>
            <img
              src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/vbet/logo.jpg"
              alt=""
              @click="$router.push('/home')"
            />
            <!-- LOGO -->
          </div>
          <div class="d-flex" v-if="$route.path !== '/auth/login'">
            <!-- <div
                            class="
                                navbar-brand
                                text-white
                                mx-3
                                d-flex
                                flex-column
                            "
                            @click="$router.push('/contact-us')"
                        >
                            <i class="fas fa-phone-volume mx-auto"></i>
                            <div>
                                {{
                                    $store.state.language === "en"
                                        ? "Service"
                                        : "ဝန်ဆောင်မူ"
                                }}
                            </div>
                        </div> -->

            <div
              class="navbar-brand text-white mx-3 d-flex flex-column"
              @click="$router.go()"
            >
              <i class="fas fa-redo mx-auto"></i>
              <div>
                {{ $store.state.language === 'en' ? 'Refresh' : 'Refresh' }}
              </div>
            </div>
          </div>
        </div>
      </nav>
    </Sticky>
  </div>
</template>
<script>
import { Toast, Dialog, Sticky } from 'vant';

export default {
  components: { Sticky },
  data() {
    return {
      imageLink: `https://ui-avatars.com/api/?name=${this.$store.state.authUser.email}&background=0D8ABC&color=fff`,
    };
  },
  methods: {
    closeNavCollapse() {
      $('#navbar-default').collapse('hide');
    },
    setLangLocalstorage(language) {
      localStorage.setItem('language', language);
      this.$store.commit('setLanguage', language);
      $('#navbar-default').collapse('hide');
    },
    redirectLink(link) {
      this.$router.push(`/${link}`).catch(() => {});
      $('#navbar-default').collapse('hide');
    },
    redirectRoute(path) {
      if (path === '2d') {
        this.$router.push('/user/2d');
      } else {
        this.$router.push('/user/3d');
      }
      return true;
    },
  },
};
</script>
<style scoped>
.navbar {
  padding: 3px;
  padding-left: 10px;
  /* padding-top: 6px;
  padding-bottom: 6px; */
  /* border-bottom: 2px solid #1C203B; */
}
.navbar-horizontal .navbar-brand img {
  height: 50px;
}
.nav-link {
  cursor: pointer;
}
.navbar-brand.brand-text {
  font-size: 20px;
  color: red;
  margin-left: 0px;
  margin-right: 10px;
}
.navbar-brand {
  font-size: 16px;
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
}
.dropdown-item {
  cursor: pointer;
}
.bg-default {
  background-color: #02133E !important;
}
@media (max-width: 767.98px) {
  .navbar-brand {
    font-size: 14px;
    margin-left: 0px;
    margin-right: 0px;
  }
}
</style>

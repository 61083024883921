export const routes = [
  {
    path: '/data-table',
    component: render('frontend/DataTable'),
  },
  {
    path: '/landing',
    component: render('frontend/MainMenu'),
  },
  {
    path: '/android-guide',
    component: render('frontend/AndroidGuide'),
  },
  {
    path: '/ios-guide',
    component: render('frontend/IOSGuide'),
  },
  {
    path: '/auth/login',
    component: render('frontend/auth/Login'),
  },
  {
    path: '/',
    component: render('frontend/Index'),
    children: [
      {
        path: '',
        component: render('frontend/Home'),
      },
      {
        path: 'home',
        component: render('frontend/Home'),
        
      },
      {
        path: 'rule',
        component: render('frontend/auth/Rule'),
        
      },
      
      {
        path: 'betslip-history',
        component: render('frontend/BetslipHistory'),
        
      },
      {
        path: 'history',
        component: render('frontend/History'),
        
      },
      {
        path: 'maung-fb',
        component: render('frontend/MaungFB'),
      },
      {
        path: 'football-result',
        component: render('frontend/MatchResult'),
      },
      {
        path: 'body-fb',
        component: render('frontend/BodyFB'),
        
      },
      {
        path: 'setting',
        component: render('frontend/Setting'),
        
      },
      {
        path: 'contact-us',
        component: render('frontend/ContactUs'),
        
      },
      {
        path: 'live',
        component: render('frontend/Live'),
        
      },
      {
        path: 'video-links',
        component: render('frontend/VideoLink'),
        
      },
      {
        path: 'deposit',
        component: render('frontend/Deposit'),
        
      },
      {
        path: 'withdraw',
        component: render('frontend/Withdraw'),
      },
    ],
  },
];

function render(template) {
  return require(`@/pages/${template}.vue`).default;
}

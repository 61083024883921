<template>
	<div style="padding-bottom: 10rem;">
		<div :style="itemsCart.length > 0 ? 'margin-bottom: 7rem !important' : ''">
			<div class="container d-flex justify-content-center mb-3 mt-3">
				<div
					class="mr-2 pt-1"
					style="border-radius: 10px"
					v-if="todayMatches.length > 0 || yesterdayMatches.length > 0"
				>
					<div class="tabs">
						<button
							class="tab-button"
							:class="tab === 'Yesterday' ? 'active' : ''"
							@click="openTab('Yesterday')"
						>
							Yesterday
						</button>
						<button
							class="tab-button"
							:class="tab === 'Today' ? 'active' : ''"
							@click="openTab('Today')"
						>
							Today
						</button>

					</div>
					<!-- <b class="text-white px-3">Today Matches</b> -->
				</div>
				<div class="text-center align-items-center mt-5 pt-5" v-else>
					<Loading
						color="#000000"
						size="24"
						class="px-4 py-0 m-auto pb-2 pt-5"
					/>
					<span class="text-danger font-weight-bold">
						{{
							$store.state.language === "en"
								? "Please wait. "
								: "ဘောလုံးပွဲစဉ်များအား ရယူနေပါသည်။ "
						}}
					<br>
						{{
							$store.state.language === "en"
								? "Football matches are fetching! "
								: "ခေတ္တစောင့်ဆိုင်းပေးပါ။"
						}}
					</span>
				</div>

			</div>
			<div class="container">
				<div
					class="card my-3"
					v-for="(item, itemKey) in tab === 'Today'
						? todayMatches
						: yesterdayMatches"
					:key="itemKey"
				>
					<div class="d-flex flex-column p-3">
						<div class="d-flex mb-2 justify-content-center">
							<h4 style="text-align: center">{{ item.league }}</h4>
						</div>
						<div class="d-flex justify-content-around">
							<div
								class="d-flex flex-column align-items-center"
								style="width: 130px"
							>
								<!-- <img :src="item.home_team_logo" /> -->
								<h3 class="pt-2" style="font-weight: bold">
									{{ item.first_team }}
								</h3>
							</div>
							<div class="d-flex flex-column">
								<div class="d-flex flex-column">
									<span class="text-black mx-auto" style="letter-spacing: 2px; color: black;"> {{ item.is_result ? 'FT' : '' }} </span>
									<span class="text-black my-auto mx-auto" v-if="!item.is_result">Vs</span>
									<span class="text-black my-auto mx-auto" style="font-size: 1.3rem !important; color: black;" v-if="item.is_result && !item.is_cancel">{{ item.first_team_result }} <span class="mx-2 text-black"> - </span>{{ item.second_team_result }}</span>
									<span class="my-auto text-danger mx-auto" style="letter-spacing: 2px; color: red;" v-if="item.is_cancel">PP</span>
								</div>
								<div
									class="mx-auto"
								>
									<p class="pt-2 my-auto text-nowrap" style="font-size: 0.8rem !important;">
										{{ matchTime(item.start_time) }}
									</p>
								</div>
							</div>
							<div
								class="d-flex flex-column align-items-center"
								style="width: 130px"
							>
								<!-- <img :src="item.away_team_logo" /> -->
								<h3 class="pt-2" style="font-weight: bold">
									{{ item.second_team }}
								</h3>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- <div class="container d-flex justify-content-center mb-3">
                <div class="mr-2" style="width: 120px; height: 25px; border-radius: 10px; background: blue;">
                        <b class="text-white p-2">Coming Soon...</b>
                </div>
            </div>
            <div class="container">
                <div class="card work" v-for="item in itemsCart" :key="item.id">
                    <div class="d-flex justify-content-around pt-3">
                        <div class="d-flex flex-column align-items-center">
                            <img :src="item.firstImg">
                            <h4 class="pt-2" style="font-weight: bold;">{{ item.firstTeamName }}</h4>
                        </div>
                        <p class="text-white pt-1 my-auto" id="pTag">Vs</p>
                        <div class="d-flex flex-column align-items-center">
                            <img :src="item.secondImg">
                            <h4 class="pt-2" style="font-weight: bold;">{{ item.secondTeamName }}</h4>
                        </div>
                    </div>
                    <div class="text-center">
                        <b>{{ item.CountryName }}</b>
                    </div>
                    <div class="layer">
                        <i class="fa-solid fa-circle-play text-white" style="font-size: 50px !important;"></i>
                        <h5 class="pt-1 text-white">
                            {{
                                $store.state.language === "en"
                                ? "Play"
                                : "Play"
                            }}
                        </h5>
                    </div>
                </div>
            </div> -->
		</div>
	</div>
</template>

<script>
import { Loading } from 'vant';
	export default {
		components: { Loading },
		name: "Live",
		data: () => ({
			itemsCart: [],
			tab: "Today",
			todayMatches: [],
			todayMatches: [],
			yesterdayMatches: [],
		}),
		methods: {
			matchTime(startTime){
				const date = new Date(startTime);

				const formattedDate = date.toLocaleString("en-US", {
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
				hour12: true,
				});

				// Convert MM/DD/YYYY to YYYY-MM-DD
				const [month, day, year] = formattedDate.split(",")[0].split("/");
				const time = formattedDate.split(",")[1].trim(); // Extract time part

				const finalDate = `${year}-${month}-${day} ${time}`;
				return finalDate;
			},
			openTab(tabName) {
				this.tab = tabName;
			},
			async fetchMatches() {
				try {
					const historyRes = await axios.get(
					  'fb-matches-history',
					);
					this.todayMatches = historyRes.data.data.today;
					this.yesterdayMatches = historyRes.data.data.yesterday;
				} catch (error) {
					console.log(error);
				}
			},
		},
		mounted() {
			this.fetchMatches();
			const authUser = this.fetchUser();
			if (!authUser) {
				this.$router.push('/auth/login');
			}
		},
	};
</script>

<style scoped>
	.card {
		cursor: pointer;
		width: 100%;
		/* height: 170px; */
		border-radius: 10px !important;
	}

	img {
		width: 70px;
		height: 70px;
	}

	#pTag {
		width: 60px;
		height: 30px;
		background: #0473eb;
		font-weight: bolder;
		border-radius: 20px;
		text-align: center;
	}

	.layer {
		opacity: 0.9;
		width: 100%;
		height: 0;
		background: linear-gradient(#d7d2c9, #222222);
		border-radius: 10px;
		position: absolute;
		left: 0;
		bottom: 0;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding: 0 40px;
		text-align: center;
		font-size: 14px;
		transition: height 0.5s;
	}

	.work:hover img {
		transform: scale(1.1);
	}

	.work:hover .layer {
		height: 100%;
	}

	.live {
		animation-name: live-number-animation;
		animation-duration: 2s;
		animation-iteration-count: infinite;
	}

	@keyframes live-number-animation {
		from {
			background-color: red;
			color: white;
		}

		to {
			background-color: #fdd1da;
			color: red;
		}
	}

	.coming {
		animation-name: coming-number-animation;
		animation-duration: 2s;
		animation-iteration-count: infinite;
	}

	@keyframes coming-number-animation {
		from {
			background-color: blue;
			color: white;
		}

		to {
			background-color: #aaedf9;
			color: #03acca;
		}
	}

	/* NEw */
	.tabs {
		display: flex;
		background-color: #ffffff;
		border-radius: 10px;
		overflow: hidden;
		width: 300px;
		padding: 4px;
		gap: 4px;
	}

	.tab-button {
		flex-grow: 1;
		background-color: #ffffff;
		border: none;
		border-radius: 10px;
		outline: none;
		cursor: pointer;
		padding: 10px 12px;
		transition: background-color 0.3s ease;
		font-size: 17px;
	}

	.tab-button:hover,
	.tab-button.active {
		background-color: #0D945D;
		color: white;
	}
</style>

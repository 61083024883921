<template>
  <div 
    style="background: #092305 !important;
    background-image: url('https://space-tech.sgp1.cdn.digitaloceanspaces.com/vbet22/home/HomepageBackground.png'); 
    min-height: 100vh; 
    height: auto; 
    overflow-y: scroll;
    background-size: cover; 
    background-attachment: fixed;"
  >
    <!-- <img src="/build/assets/img/brand/khit_thit.jpg" alt="" /> -->
    <!-- <div class="col-12 d-flex my-0">
      <img
        class="mx-auto"
        src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/vbet/logo.jpg"
        alt=""
        style="width: 100px; height: auto; margin-top: -22px"
      />
    </div> -->
    
    <div class="col-lg-7 col-12 mx-auto p-0 d-flex main-image-wrapper">
            <b-carousel
                id="carousel-1"
                :interval="3000"
                controls
                fade
                style="text-shadow: 1px 1px 2px #333; width: 100%; height: 100%; padding: 5px;"
                v-if="!threedData.embedded_link && !threedData.isLive"
            >
                <b-carousel-slide
                    img-src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/vbet/main1.png"
                ></b-carousel-slide>
                <b-carousel-slide
                    img-src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/vbet/main2.png"
                ></b-carousel-slide>
                <!-- <b-carousel-slide
                    img-src="https://cdn.gold549.com/build/assets/img/theme/main3.jpg"
                ></b-carousel-slide> -->
            </b-carousel>
        </div>

    <div style="padding-left: 10px; padding-right: 10px;">
      <NoticeBar
        class="col-lg-12 col-12 mx-auto notice-bar"
        scrollable
        :text="websiteInfo[0]?.noticeText"
        v-if="!threedData.embedded_link && !threedData.isLive"
      />
    </div>

    <div class="col-12 account-container-wrapper d-flex py-1 my-1">
      <div class="col-lg-6 col-5 my-auto">
        <img src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/vbet22/home/ProfileIcon.png" style="width: auto; height: 92px" alt="" @click="redirectRoute('setting')">
      </div>

      <div class="col-lg-6 col-7 p-0">
        <div class="pt-2">
          <div class="d-flex">
            <p class="css-17h40hp mb-1" style="width: 85px;"> 
                        {{
                            $store.state.language === "en"
                                ? "Username"
                                : "အမည်"
                        }}</p>
            <p class="css-eurmd1 mb-1" style="color: #D4DDF4; font-weight: 400;">: {{$store.state.authUser.name}} </p>
          </div>
          <div class="d-flex">
            <p class="css-17h40hp mb-1" style="width: 85px">
                        {{
                            $store.state.language === "en"
                                ? "Balance"
                                : "လက်ကျန်ငွေ"
                        }}</p>
            <p class="css-eurmd1 mb-1" style="color: #D4DDF4; font-weight: 400;">:
              <b>{{ Number($store.state.amount).toLocaleString() }}</b>
            {{ $store.state.language === 'en' ? 'MMK' : 'ကျပ်' }} </p>
          </div>
          <div class="d-flex">
            <p class="css-17h40hp mb-1" style="width: 85px">
                        {{
                            $store.state.language === "en"
                                ? "Phone"
                                : "ဖုန်းနံပါတ်"
                        }}</p>
            <p class="css-eurmd1 mb-1" style="color: #D4DDF4; font-weight: 400;">: {{ $store.state.authUser.phone }} </p>
          </div>
          <div class="d-flex">
            <p class="css-17h40hp mb-1" style="width: 85px">
                        {{
                            $store.state.language === "en"
                                ? "Withdraw"
                                : "ငွေထုတ်"
                        }}</p>
            <p class="css-eurmd1 mb-1" style="color: #D4DDF4; font-weight: 400;">: {{ Number($store.state.totalWithdraw).toLocaleString() }} {{ $store.state.language === 'en' ? 'MMK' : 'ကျပ်' }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12" style="padding-left: 0.7rem; padding-right: 0.7rem;">
      <div class="col-12 py-2 px-0 my-1 d-flex justify-content-between"  style="background: #092305; border-radius: 5px;">
        <div class="col-6 d-flex" style="padding-right: 0.4rem !important; position: relative;">
          <img v-if="type === 'football'" src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/vbet22/home/CategoryButtomActive.png" style="width: 100%; height: 3.4rem;" alt="">
          <img class="my-auto" v-else src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/vbet22/home/CategoryButtomInctive.png" style="width: 100%; height: 3rem;" alt="" @click="type = 'football'">
          <span class="customize-text my-auto" style="position: absolute; font-size: 1rem; color: white; left: 40%; top: 10%;" @click="type = 'football'">SPORTS</span>
        </div>
        <div class="col-6 d-flex" style="padding-left: 0.4rem !important; position: relative;">
          <img v-if="type === 'card'" src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/vbet22/home/CategoryButtomActive.png" style="width: 100%; height: 3.4rem;" alt="">
          <img class="my-auto" v-else src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/vbet22/home/CategoryButtomInctive.png" style="width: 100%; height: 3rem;" alt="" @click="type = 'card'">
          <span class="customize-text my-auto" style="position: absolute; font-size: 1rem; color: white; left: 38%; top: 10%;" @click="type = 'card'">CARD</span>
        </div>
      </div>
    </div>

    <div class="justify-content-center cards-container-mobile" v-if="type === 'football'">
      <div
        class="col-xl-6 col-6 home-cards pr-1 my-1 mx-0"
        @click="redirectRoute('body-fb')"
      >
        <!-- <div
          class="d-flex justify-content-center align-items-center mb-2 pt-2"
          style="width: 100%; height: 80px; border-radius: 5px"
        > -->
          <div class="my-auto">
            <img
              src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/vbet22/home/Body.png"
              alt=""
              style="width: 100%; height: auto"
            />
          </div>
          <!-- <div class="pt-1">
            <h4 style="color: #D4DDF4">
                {{ $store.state.language === 'en' ? 'Body' : 'ဘော်ဒီ' }}
            </h4>
          </div> -->
        <!-- </div> -->
      </div>

      <div
        class="col-xl-6 col-6 home-cards pl-1 my-1"
        @click="redirectRoute('maung-fb')"
      >
        <!-- <div
          class="card d-flex justify-content-center align-items-center mb-2 pt-2"
          style="width: 100%; height: 80px; border-radius: 5px"
        > -->
          <div>
            <img
              src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/vbet22/home/Maung.png"
              alt=""
              style="width: 100%; height: auto"
            />
          </div>
          <!-- <div class="pt-1">
            <h4 style="color: #D4DDF4">
              {{ $store.state.language === 'en' ? 'Maung' : 'မောင်း' }}
            </h4>
          </div>
        </div> -->
      </div>

      <div
        class="col-xl-6 col-6 home-cards pr-1 my-1"
        @click="redirectRoute('betslip-history')"
      >
        <!-- <div
          class="card d-flex justify-content-center align-items-center mb-2 pt-2"
          style="width: 100%; height: 80px; border-radius: 5px"
        > -->
          <div>
            <img
              src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/vbet22/home/Voucher.png"
              alt=""
              style="width: 100%; height: auto"
            />
          </div>
          <!-- <div class="pt-1">
            <h4 style="color: #D4DDF4">
              {{
                $store.state.language === 'en'
                  ? 'Voucher List'
                  : 'လောင်းထားသောပွဲများ'
              }}
            </h4>
          </div>
        </div> -->
      </div>
      <div
        class="col-xl-6 col-6 home-cards pl-1 my-1"
        @click="redirectRoute('history')"
      >
        <!-- <div
          class="card d-flex justify-content-center align-items-center mb-2 pt-2"
          style="width: 100%; height: 80px; border-radius: 5px"
        > -->
          <div>
            <img
              src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/vbet22/home/Cash%20History.png"
              alt=""
              style="width: 100%; height: auto"
            />
          </div>
          <!-- <div class="pt-1">
            <h4 style="color: #D4DDF4">
              {{
                $store.state.language === 'en'
                  ? 'Transactions'
                  : 'ငွေစာရင်းများ'
              }}
            </h4>
          </div>
        </div> -->
      </div>


      <div
        class="col-xl-6 col-6 home-cards pr-1 my-1"
        @click="redirectRoute('deposit')"
      >
        <!-- <div
          class="card d-flex justify-content-center align-items-center mb-2 pt-2"
          style="width: 100%; height: 80px; border-radius: 5px"
        > -->
          <div>
            <img
              src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/vbet22/home/Cash%20In.png"
              alt=""
              style="width: 100%; height: auto"
            />
          </div>
          <!-- <div class="pt-1">
            <h4 style="color: #D4DDF4">
              {{
                $store.state.language === 'en'
                  ? 'Deposit'
                  : 'ငွေသွင်းရန်'
              }}
            </h4>
          </div>
        </div> -->
      </div>

      <div
        class="col-xl-6 col-6 home-cards pl-1 my-1"
        @click="redirectRoute('withdraw')"
      >
        <!-- <div
          class="card d-flex justify-content-center align-items-center mb-2 pt-2"
          style="width: 100%; height: 80px; border-radius: 5px"
        > -->
          <div>
            <img
              src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/vbet22/home/Cash%20Out.png"
              alt=""
              style="width: 100%; height: auto"
            />
          </div>
          <!-- <div class="pt-1">
            <h4 style="color: #D4DDF4">
              {{
                $store.state.language === 'en'
                  ? 'Withdraw'
                  : 'ငွေထုတ်ရန်'
              }}
            </h4>
          </div>
        </div> -->
      </div>

      <div class="col-xl-6 col-6 home-cards pr-1 my-1" @click="redirectRoute('football-result')">
        <!-- <div
          class="card d-flex justify-content-center align-items-center mb-2 pt-2"
          style="width: 100%; height: 80px; border-radius: 5px"
        > -->
          <div>
            <img
              src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/vbet22/home/End%20Fixture.png"
              alt=""
              style="width: 100%; height: auto"
            />
          </div>
          <!-- <div class="pt-1">
            <h4 style="color: #D4DDF4">
              {{ $store.state.language === 'en' ? 'Setting' : 'ကိုယ်ရေးအချက်အလက်' }}
            </h4>
          </div>
        </div> -->
      </div>

      <div
        class="col-xl-6 col-6 home-cards pl-1 my-1"
        @click="redirectRoute('Live')"
      >
        <!-- <div
          class="card d-flex justify-content-center align-items-center mb-2 pt-2"
          style="width: 100%; height: 80px; border-radius: 5px"
        > -->
          <div>
            <img
              src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/vbet22/home/Live.png"
              alt=""
              style="width: 100%; height: auto"
            />
          </div>
          <!-- <div class="pt-1">
            <h4 style="color: #D4DDF4">
              Live
            </h4>
          </div>
        </div> -->
      </div>
      <!-- <div class="col-12 d-flex justify-content-end px-0">
        <div
          class="home-cards pl-1 mr-0 mt-4 mb-3"
          @click="logout()"
        >
              <img
                src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/vbet/Logout.png"
                alt=""
                style="width: auto; height: 35px"
              />
        </div>
      </div> -->
      
    </div>

    <div :class="type === 'skm' ? 'active' : ''" v-if="type === 'skm'" class="d-flex justify-content-center flex-wrap">
      <img class="mx-1 my-1" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/general/%E1%80%9C%E1%80%B0%E1%80%9E%E1%80%85%E1%80%BA.png" alt="skm" style="cursor: pointer; width: 30%; height: auto;" @click="fetchShanKoMeeData(0)">
      <img class="mx-1 my-1" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/general/%E1%80%82%E1%80%BB%E1%80%B0%E1%80%94%E1%80%AE%E1%80%9A%E1%80%AC.png" alt="skm" style="cursor: pointer; width: 30%; height: auto;" @click="fetchShanKoMeeData(1)">
      <img class="mx-1 my-1" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/general/%E1%80%85%E1%80%AE%E1%80%94%E1%80%AE%E1%80%9A%E1%80%AC.png" alt="skm" style="cursor: pointer; width: 30%; height: auto;" @click="fetchShanKoMeeData(2)">
      <img class="mx-1 my-1" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/general/%E1%80%98%E1%80%B1%E1%80%AC%E1%80%B7%E1%80%85%E1%80%BA.png" alt="skm" style="cursor: pointer; width: 30%; height: auto;" @click="fetchShanKoMeeData(3)">
      <img class="mx-1 my-1" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/general/%E1%80%99%E1%80%AE%E1%80%9C%E1%80%AE%E1%80%9A%E1%80%B6%E1%80%94%E1%80%AC.png" alt="skm" style="cursor: pointer; width: 30%; height: auto;" @click="fetchShanKoMeeData(4)">
      <img class="mx-1 my-1" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/general/%E1%80%98%E1%80%AE%E1%80%9C%E1%80%AE%E1%80%9A%E1%80%B6%E1%80%94%E1%80%AC.png" alt="skm" style="cursor: pointer; width: 30%; height: auto;" @click="fetchShanKoMeeData(5)">
    </div>

    <div :class="type === 'bgy' ? 'active' : ''" v-if="type === 'bgy'" class="d-flex justify-content-center flex-wrap">
      <img class="mx-1 my-1" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/general/%E1%80%9C%E1%80%B0%E1%80%9E%E1%80%85%E1%80%BA.png" alt="bgy" style="cursor: pointer; width: 30%; height: auto;" @click="fetchBugyee(0)">
      <img class="mx-1 my-1" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/general/%E1%80%82%E1%80%BB%E1%80%B0%E1%80%94%E1%80%AE%E1%80%9A%E1%80%AC.png" alt="bgy" style="cursor: pointer; width: 30%; height: auto;" @click="fetchBugyee(1)">
      <img class="mx-1 my-1" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/general/%E1%80%85%E1%80%AE%E1%80%94%E1%80%AE%E1%80%9A%E1%80%AC.png" alt="bgy" style="cursor: pointer; width: 30%; height: auto;" @click="fetchBugyee(2)">
      <img class="mx-1 my-1" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/general/%E1%80%98%E1%80%B1%E1%80%AC%E1%80%B7%E1%80%85%E1%80%BA.png" alt="bgy" style="cursor: pointer; width: 30%; height: auto;" @click="fetchBugyee(3)">
      <img class="mx-1 my-1" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/general/%E1%80%99%E1%80%AE%E1%80%9C%E1%80%AE%E1%80%9A%E1%80%B6%E1%80%94%E1%80%AC.png" alt="bgy" style="cursor: pointer; width: 30%; height: auto;" @click="fetchBugyee(4)">
      <img class="mx-1 my-1" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/general/%E1%80%98%E1%80%AE%E1%80%9C%E1%80%AE%E1%80%9A%E1%80%B6%E1%80%94%E1%80%AC.png" alt="bgy" style="cursor: pointer; width: 30%; height: auto;" @click="fetchBugyee(5)">
    </div>

    <div class="justify-content-center cards-container-mobile" v-if="type === 'card'">
      <div
        class="col-xl-6 col-6 home-cards pr-1 my-1 mx-0"
        @click="type = 'skm'"
      >
        <!-- <div
          class="d-flex justify-content-center align-items-center mb-2 pt-2"
          style="width: 100%; height: 80px; border-radius: 5px"
        > -->
          <div class="my-auto">
            <img
              src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/vbet22/home/Shan.png"
              alt=""
              style="width: 100%; height: auto"
            />
          </div>
          <!-- <div class="pt-1">
            <h4 style="color: #D4DDF4">
                {{ $store.state.language === 'en' ? 'Body' : 'ဘော်ဒီ' }}
            </h4>
          </div> -->
        <!-- </div> -->
      </div>

      <div
        class="col-xl-6 col-6 home-cards pl-1 my-1"
       @click="type = 'bgy'"
      >
        <!-- <div
          class="card d-flex justify-content-center align-items-center mb-2 pt-2"
          style="width: 100%; height: 80px; border-radius: 5px"
        > -->
          <div>
            <img
              src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/vbet22/home/Bugyi.png"
              alt=""
              style="width: 100%; height: auto"
            />
          </div>
          <!-- <div class="pt-1">
            <h4 style="color: #D4DDF4">
              {{ $store.state.language === 'en' ? 'Maung' : 'မောင်း' }}
            </h4>
          </div>
        </div> -->
      </div>

      <div
        class="col-xl-6 col-6 home-cards pr-1 my-1"
        @click="alertBoxForComing()"
      >
        <!-- <div
          class="card d-flex justify-content-center align-items-center mb-2 pt-2"
          style="width: 100%; height: 80px; border-radius: 5px"
        > -->
          <div>
            <img
              src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/vbet22/home/Bugyi%20copy.png"
              alt=""
              style="width: 100%; height: auto"
            />
          </div>
          <!-- <div class="pt-1">
            <h4 style="color: #D4DDF4">
              {{
                $store.state.language === 'en'
                  ? 'Voucher List'
                  : 'လောင်းထားသောပွဲများ'
              }}
            </h4>
          </div>
        </div> -->
      </div>
      <div
        class="col-xl-6 col-6 home-cards pl-1 my-1"
        @click="alertBoxForComing()"
      >
        <!-- <div
          class="card d-flex justify-content-center align-items-center mb-2 pt-2"
          style="width: 100%; height: 80px; border-radius: 5px"
        > -->
          <div>
            <img
              src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/vbet22/home/Bagan%20Slot.png"
              alt=""
              style="width: 100%; height: auto"
            />
          </div>
          <!-- <div class="pt-1">
            <h4 style="color: #D4DDF4">
              {{
                $store.state.language === 'en'
                  ? 'Transactions'
                  : 'ငွေစာရင်းများ'
              }}
            </h4>
          </div>
        </div> -->
      </div>


      <div
        class="col-xl-6 col-6 home-cards pr-1 my-1"
        @click="redirectRoute('deposit')"
      >
        <!-- <div
          class="card d-flex justify-content-center align-items-center mb-2 pt-2"
          style="width: 100%; height: 80px; border-radius: 5px"
        > -->
          <div>
            <img
              src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/vbet22/home/Cash%20In.png"
              alt=""
              style="width: 100%; height: auto"
            />
          </div>
          <!-- <div class="pt-1">
            <h4 style="color: #D4DDF4">
              {{
                $store.state.language === 'en'
                  ? 'Deposit'
                  : 'ငွေသွင်းရန်'
              }}
            </h4>
          </div>
        </div> -->
      </div>

      <div
        class="col-xl-6 col-6 home-cards pl-1 my-1"
        @click="redirectRoute('withdraw')"
      >
        <!-- <div
          class="card d-flex justify-content-center align-items-center mb-2 pt-2"
          style="width: 100%; height: 80px; border-radius: 5px"
        > -->
          <div>
            <img
              src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/vbet22/home/Cash%20Out.png"
              alt=""
              style="width: 100%; height: auto"
            />
          </div>
          <!-- <div class="pt-1">
            <h4 style="color: #D4DDF4">
              {{
                $store.state.language === 'en'
                  ? 'Withdraw'
                  : 'ငွေထုတ်ရန်'
              }}
            </h4>
          </div>
        </div> -->
      </div>
      
    </div>
    <div class="col-12 d-flex justify-content-end px-0 pt-4">
        <img src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/vbet22/home/LogoutButtom.png" @click="logout()" class="col-4" style="margin-right: -0.5rem;" alt="">
      </div>
  </div>
</template>
<script>
import { NoticeBar, Dialog, Toast } from 'vant';
import { mapActions } from 'vuex';

export default {
  components: { NoticeBar },
  data() {
    return {
      twoDdata: {},
      twoDHistories: {},
      threeDHistories: {},
      threedData: {},
      websiteInfo: {},
      type: 'football'
    };
  },
  methods: {
    ...mapActions(['logoutUser', 'fetchUser']),
    async fetchBugyee(level = null) {
      try {
        this.isLoading = true;
        const authUser = await this.fetchUser();
        if (!authUser) {
          this.$router.push('/auth/login');
        }
        
        const amount = authUser.amount;
        if(level == 0 && amount < 1000){
            const limitText = "1,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            // $('#limit').modal('show');
            this.dialogAlertBox(limitText)
            this.isLoading = false;
            return false;
        }
        if(level == 0 && amount > 100000){
            const limitText = "သတ်မှတ်ထားသော ငွေပမာဏထပ် ကျော်လွန်နေပါသည်။ သင့်လျော်သောအခန်းတွင်သာ ကစားပေးပါ။";
            // $('#limit').modal('show');
            this.dialogAlertBox(limitText)
            this.isLoading = false;
            return false;
        }

        if(level == 1 && amount < 3000){
            const limitText = "3,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            // $('#limit').modal('show');
            this.dialogAlertBox(limitText)
            this.isLoading = false;
            return false;
        }
        if(level == 1 && amount > 300000){
            const limitText = "သတ်မှတ်ထားသော ငွေပမာဏထပ် ကျော်လွန်နေပါသည်။ သင့်လျော်သောအခန်းတွင်သာ ကစားပေးပါ။";
            // $('#limit').modal('show');
            this.dialogAlertBox(limitText)
            this.isLoading = false;
            return false;
        }

        if(level == 2 && amount < 5000){
            const limitText = "5,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            // $('#limit').modal('show');
            this.dialogAlertBox(limitText)
            this.isLoading = false;
            return false;
        }
        if(level == 2 && amount > 500000){
            const limitText = "သတ်မှတ်ထားသော ငွေပမာဏထပ် ကျော်လွန်နေပါသည်။ သင့်လျော်သောအခန်းတွင်သာ ကစားပေးပါ။";
            // $('#limit').modal('show');
            this.dialogAlertBox(limitText)
            this.isLoading = false;
            return false;
        }
        
        if(level == 3 && amount < 10000){
            const limitText = "10,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            // $('#limit').modal('show');
            this.dialogAlertBox(limitText)
            this.isLoading = false;
            return false;
        }
        if(level == 4 && amount < 30000){
            const limitText = "30,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            // $('#limit').modal('show');
            this.dialogAlertBox(limitText)
            this.isLoading = false;
            return false;
        }
        if(level == 5 && amount < 50000){
            const limitText = "50,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            // $('#limit').modal('show');
            this.dialogAlertBox(limitText)
            this.isLoading = false;
            return false;
        }

            const res = await axios.get('/bugyee-data', {
            params: {
                    id : this.$store.state.authUser.name,
                    level,
                    balance : this.$store.state.authUser.amount,
                    info : {
                            nickname : this.$store.state.authUser.user_name,
                            profile : 3
                        }
                    },
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            });
            // console.log(res.data)
            if(res.data.url){
              window.location.assign(res.data.url)
            } else {
              Dialog.alert({
                message: res.data.description,
                confirmButtonText:
                                this.$store.state.language === 'en'
                                  ? 'Go Back'
                                  : 'နောက်သို့ပြန်သွားမည်။',
              });
            }
            
            this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },

    async fetchShanKoMeeData(level = null) {
      try {
        this.isLoading = true;
        const authUser = await this.fetchUser();
        if (!authUser) {
          this.$router.push('/auth/login');
        }
        
        const amount = authUser.amount;
        if(level == 0 && amount < 1000){
            const limitText = "1,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            // $('#limit').modal('show');
            this.dialogAlertBox(limitText)
            this.isLoading = false;
            return false;
        }
        if(level == 0 && amount > 100000){
            const limitText = "သတ်မှတ်ထားသော ငွေပမာဏထပ် ကျော်လွန်နေပါသည်။ သင့်လျော်သောအခန်းတွင်သာ ကစားပေးပါ။";
            // $('#limit').modal('show');
            this.dialogAlertBox(limitText)
            this.isLoading = false;
            return false;
        }

        if(level == 1 && amount < 3000){
            const limitText = "3,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            // $('#limit').modal('show');
            this.dialogAlertBox(limitText)
            this.isLoading = false;
            return false;
        }
        if(level == 1 && amount > 300000){
            const limitText = "သတ်မှတ်ထားသော ငွေပမာဏထပ် ကျော်လွန်နေပါသည်။ သင့်လျော်သောအခန်းတွင်သာ ကစားပေးပါ။";
            // $('#limit').modal('show');
            this.dialogAlertBox(limitText)
            this.isLoading = false;
            return false;
        }

        if(level == 2 && amount < 5000){
            const limitText = "5,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            // $('#limit').modal('show');
            this.dialogAlertBox(limitText)
            this.isLoading = false;
            return false;
        }
        if(level == 2 && amount > 500000){
            const limitText = "သတ်မှတ်ထားသော ငွေပမာဏထပ် ကျော်လွန်နေပါသည်။ သင့်လျော်သောအခန်းတွင်သာ ကစားပေးပါ။";
            // $('#limit').modal('show');
            this.dialogAlertBox(limitText)
            this.isLoading = false;
            return false;
        }
        
        if(level == 3 && amount < 10000){
            const limitText = "10,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            // $('#limit').modal('show');
            this.dialogAlertBox(limitText)
            this.isLoading = false;
            return false;
        }
        if(level == 4 && amount < 30000){
            const limitText = "30,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            // $('#limit').modal('show');
            this.dialogAlertBox(limitText)
            this.isLoading = false;
            return false;
        }
        if(level == 5 && amount < 50000){
            const limitText = "50,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            // $('#limit').modal('show');
            this.dialogAlertBox(limitText)
            this.isLoading = false;
            return false;
        }

        // if(this.nickname && this.nickname !== ''){
            const res = await axios.get('/shankomee-data', {
            params: {
                    id : this.$store.state.authUser.name,
                    level,
                    balance : this.$store.state.authUser.amount,
                    info : {
                            nickname : this.$store.state.authUser.user_name,
                            profile : 3
                        }
                    },
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            });

            if(res.data.url){
              window.location.assign(res.data.url)
            } else {
              Dialog.alert({
                message: res.data.description,
                confirmButtonText:
                                this.$store.state.language === 'en'
                                  ? 'Go Back'
                                  : 'နောက်သို့ပြန်သွားမည်။',
              });
            }

            this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },

    async fetchForestData() {
      try {
        this.isLoading = true;
        const authUser = await this.fetchUser();
        if (!authUser) {
          this.$router.push('/auth/login');
        }

        // if(this.nickname && this.nickname !== ''){
            const res = await axios.get('/forest-data', {
            params: {
                      id : this.$store.state.authUser.name,
                      balance : this.$store.state.authUser.amount,
                    },
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            });

            if(res.data.url){
              window.location.assign(res.data.url)
            } else {
              Dialog.alert({
                message: res.data.description,
                confirmButtonText:
                                this.$store.state.language === 'en'
                                  ? 'Go Back'
                                  : 'နောက်သို့ပြန်သွားမည်။',
              });
            }

            this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },

    alertBoxForComing(){
      Dialog.alert({
        title: 'အသိပေးချက်။',
        message: 'ဖဲဂိမ်းများကို မကြာမီရက်ပိုင်းအတွင်း ဆော့ကစားလို့ရတော့မှာ ဖြစ်ပါတယ်ရှင့်။',
        confirmButtonText: 'Okay',
      }).then(() => {
        // on close
      });
    },
    dialogAlertBox(message){
      Dialog.alert({
        title: 'အသိပေးချက်။',
        message: message,
        confirmButtonText: 'Okay',
      }).then(() => {
        // on close
      });
    },
    logout() {
      Dialog.confirm({
        title:
          this.$store.state.language === 'en'
            ? 'Are you sure to log out?'
            : 'ထွက်မှာ သေချာပါသလား?',
        cancelButtonText:
          this.$store.state.language === 'en' ? 'Cancel' : 'မထွက်တော့ပါ',
        confirmButtonText:
          this.$store.state.language === 'en' ? 'Sure' : 'သေချာပြီ',
      })
        .then(async () => {
          await this.logoutUser();
          this.$router.push('/auth/login');
          Toast.success(
            this.$store.state.language === 'en' ? 'Logged out!' : 'ထွက်ပြီးပြီ'
          );
        })
        .catch(() => {});
    },
    async redirectRoute(path) {
      try {
        if (path === 'tip') {
          const text =
            this.$store.state.language === 'en'
              ? 'This feature is not support yet.'
              : 'ယခုအချိန်တွင် အသုံးပြုလို့မရနိုင်သေးပါ။';
          Dialog.alert({
            message: text,
            confirmButtonText:
              this.$store.state.language === 'en' ? 'Okay' : 'Okay',
          }).then(() => {
            // on close
          });
        } else {
          this.$router.push(path);
        }
      } catch (error) {
        console.log(error);
      }
    },
    threedDrawDateFormat(date) {
      return moment(date).format('ll');
    },
    async fetchTwoDdata() {
      try {
        const res = await axios.get('/2d/data');
        this.isLive = res.data.data[0].isLive;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchWebsiteData() {
      try {
        const res = await axios.get('/website-infos');
        this.websiteInfo = res.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchThreedDdata() {
      try {
        const res = await axios.get('/3d/data');
        this.threedData = res.data.data[0];
      } catch (error) {
        console.log(error);
      }
    },
    async fetchTwoThreeDHistories() {
      try {
        const twoDres = await axios.get('/twod_history');
        const threeDres = await axios.get('/threed_history');
        this.twoDHistories = twoDres.data.data;
        this.threeDHistories = threeDres.data.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
  async mounted() {
    const authUser = await this.fetchUser();
    if (!authUser) {
      this.$router.push('/auth/login');
    }
    this.fetchWebsiteData();
    // this.fetchTwoDdata();
    // this.fetchThreedDdata();
    // this.fetchTwoThreeDHistories();
  },
};
</script>
<style scoped>
.customize-text::first-letter {
  font-size: 180%; /* Adjust to the desired size */
  color: #fff; /* Optional: change color */
  margin-right: 2px;
}
.card {
  background: #2D325A !important;
}
.css-38745a {
    margin: 0px;
    line-height: 1.5;
    font-size: 12px;
    /* font-family: NotoSansMyanmar; */
    font-weight: 500;
}
.css-17h40hp {
    margin: 0px;
    line-height: 1.5;
    font-size: 14px;
    color: white;
    /* font-family: NotoSansMyanmar; */
    font-weight: 700 !important;
}
.card {
  cursor: pointer;
  border-radius: 10px !important;
}
.main-image-wrapper img {
  width: 100%;
}
.video-container {
  height: 500px;
  width: 100%;
}
.notice-bar {
  margin-top: -40px;
  z-index: 1;
  font-size: 20px;
  color: #FFFFFF !important;
  font-weight: 500;
  background: rgba(255, 242, 0, 0.7);
}
.twod-threed-cards {
  height: 200px;
}
.live-number {
  font-weight: bold;
  font-size: 25px;
}
.live-result {
  font-weight: bolder;
  font-size: 60px;
  animation-name: live-number-animation;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
.not-live-text {
  font-weight: bold;
  font-size: 24px;
}
.cards-container {
  display: flex;
  flex-wrap: wrap;
}
.card-body {
  padding: 0px !important;
}
.cards-container-mobile {
  display: none;
}
@keyframes live-number-animation {
  from {
    color: white;
  }
  to {
    color: red;
  }
}

/* @media (max-width: 768px) { */
  .middle-container {
    padding-top: 0.1rem !important;
  }
  hr {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .video-container {
    height: 300px;
    width: 100%;
  }
  .main-wrapper {
    padding: 7px !important;
  }
  .notice-bar {
    margin-top: 0px;
    height: 25px;
    z-index: 1;
    font-size: 12px;
    color: #02133E;
    background: #092305;
    border-radius: 5px;
  }
  .live-number {
    font-weight: bold;
    font-size: 20px;
  }
  .live-result {
    font-weight: bolder;
    font-size: 37px;
  }

  .cards-container {
    display: none;
  }
  .cards-container-mobile {
    padding: 0px !important;
    margin-top: 5px !important;
    display: flex;
    flex-wrap: wrap;
  }
  .home-cards {
    padding-left: 10px;
    padding-right: 10px;
  }
  .card-body {
    padding: 0px !important;
  }
  .not-live-text {
    font-size: 18px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .account-container-wrapper {
    width: auto;
    height: auto;
    border-radius: 5px;
    padding-left: 4px;
    padding-right: 4px;
    margin: 10px;
    color: black;
    background: #092305;
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  }
  .top-wrapper,
  .bottom-wrapper {
    padding: 10px;
    width: 100%;
    display: flex;
  }
  .top-right,
  .bottom-right {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: end;
  }
  .top-left,
  .bottom-left {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: start;
  }
/* } */
</style>
